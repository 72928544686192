<template>
  <div style=" margin: -15px -15px;width: 100%;overflow: hidden;">
    <a-spin :spinning="spinning">
      <div class="ZKAuth">
        <div class="ZKAuth-left">
          <!--        身份选择-->
          <div>
            <a-menu
                style="width: 100%"
                v-model="selectId"
                mode="inline"
            >
              <a-menu-item
                  v-for="(item,index) in templateList"
                  @click="selectStatus(item.id,item.type)"
                  :key="item.id">
                {{item.typeName}}
              </a-menu-item>
            </a-menu>
          </div>
        </div>
        <div class="ZKAuth-right">
          <div class="ZKAuth-right-top">
                关键字: <a-input
                  v-model="keyword_val"
                  style="width: 300px"
                  placeholder="请输入关键字" />
              <a-button
                  type="primary"
                  @click="selectBtn"
                  style="margin-left: 20px">查询</a-button>
              <a-button
                  @click="restBtn"
                  style="margin-left: 10px">重置</a-button>
          </div>
          <div class="ZKAuth-right-table">
            <!--   列表   -->
            <div class="ZKAuth-right-table-top-btn">
              <!--  列表上方按钮      -->
              <a-button @click="addBtn" type="primary">新增</a-button>
            </div>
            <!--   列表   -->
            <div  class="ZKAuth-right-table-top-table">
<!--              :pagination="false"  :rowKey='record=>record.id' -->
              <a-table
                  :pagination="false"
                  style="width: 99%"
                  :columns="columns"
                  :rowKey='record=>record.id'
                  :data-source="tableList">
                <span slot="operation" slot-scope="text,record">
                  <a @click="upBtn(record)">上移</a>
                  <a style="margin-left: 10px" @click="downBtn(record)">下移</a>
                  <a style="margin-left: 10px" @click="lookBtn(record)">编辑</a>
                   <a-popconfirm
                       style="margin-left: 10px"
                       title="是否确认删除?"
                       ok-text="Yes"
                       cancel-text="No"
                       @confirm="amendBtn(record.id)"
                   >
                     <a href="#">删除</a>
                   </a-popconfirm>
          </span>
              </a-table>
            </div>
            <!--      分页-->
            <div style="width: 100%">
              <div class="pageClass">
                <a-pagination
                    :default-current="page_no"
                    show-quick-jumper
                    @change="pageChange"
                    :total="total"
                />
              </div>
            </div>
          </div>
        </div>
        <!----------------------------------     对话框     --------------------------------->
        <!--    新增对话框-->
        <a-drawer
            title="新增"
            placement="right"
            :closable="false"
            :visible="addVisible"
            width="600"
            @close="addClose"
        >
          <a-form-model
              ref="content_add_ruleForm"
              :model="content_add_form"
              :rules="content_add_rules"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
          >
            <a-form-model-item  label="标题" prop="title">
              <a-input
                  style="width: 450px"
                  v-model="content_add_form.title"
                  placeholder="请输入标题"/>
            </a-form-model-item>
            <a-form-model-item  label="类型" prop="type">
              <a-select
                  mode="multiple"
                  v-model="content_add_form.type"
                  placeholder="请选择类型"
                  option-filter-prop="children"
                  style="width: 450px;margin-left: 5px"
              >
                <a-select-option
                    v-for="(type,index) in template_list"
                    :key="index + 'compere'"
                    v-model="type.type">
                  {{type.typeName}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item  label="方案" prop="content">
              <div style="width: 450px" ref="editor"></div>
            </a-form-model-item>
            <a-form-model-item  label="附件" prop="appendix">
              <div style="width: 450px;margin-bottom: 50px">
                <div class="uploadpdf" >
                  <input
                      style="width: 100%;height:100%;opacity: 0;"
                      accept=".pdf,.rar,.zip,.doc,.docx"
                      type="file"
                      @change="content_pdf"
                      ref="content_pdfUrl" />
                </div>
                <span>提示：手机端的帮助中心不显示上传的附件</span>
                <div v-for="(content,index) in content_add_form.appendix" :key="index">
                  <a @click="openFile(content.value)">{{content.name}}</a>:
                  <div v-if="content.name !== ''">
                    <a-progress
                        :percent="content.percentAge"
                        status="active" />
                  </div>
                </div>
              </div>

            </a-form-model-item>
          </a-form-model>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
          >
            <a-button @click="addClose">
              取消
            </a-button>
            <a-button style="margin-left: 15px" type="primary" @click="addSubmit">
              确定
            </a-button>

          </div>
        </a-drawer>
        <!--    修改对话框-->
        <a-drawer
            title="修改"
            placement="right"
            :closable="false"
            :visible="restVisible"
            width="600"
            @close="restClose">
          <a-form-model
              ref="content_add_ruleForm"
              :model="content_rest_form"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
          >
            <a-form-model-item  label="标题">
              <a-input
                  style="width: 450px"
                  v-model="content_rest_form.title"
                  placeholder="请输入标题"/>
            </a-form-model-item>
            <a-form-model-item  label="类型">
              <a-select
                  mode="multiple"
                  v-model="content_rest_form.type"
                  placeholder="请选择类型"
                  option-filter-prop="children"
                  style="width: 450px;margin-left: 5px"
              >
                <a-select-option
                    v-for="(type,index) in template_list"
                    :key="index + 'rest_compere'"
                    v-model="type.type">
                  {{type.typeName}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item  label="方案">
              <div style="width: 450px" ref="editors" >
              </div>
            </a-form-model-item>
            <a-form-model-item  label="附件" >
              <div style="width: 450px;margin-bottom: 50px">
                <div class="uploadpdf" >
                  <input
                      style="width: 100%;height:100%;opacity: 0;"
                      accept=".pdf,.rar,.zip,.doc,.docx"
                      type="file"
                      @change="content_pdf_rest"
                      ref="content_pdfUrl_rest" />
                </div>
                <span>提示：手机端的帮助中心不显示上传的附件</span>
                <div v-for="(content,index) in content_rest_form.appendix" :key="index">
                  <a @click="openFileRest(content.value)">{{content.name}}</a>:
                  <div v-if="content.name !== ''">
                    <a-progress
                        :percent="content.percentAge"
                        status="active" />
                  </div>
                </div>
              </div>
            </a-form-model-item>
          </a-form-model>
          <div
              :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }">
            <a-button @click="restClose">
              取消
            </a-button>
            <a-button style="margin-left: 15px" type="primary" @click="restSubmit">
              确定
            </a-button>

          </div>
        </a-drawer>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {
  del_help_center_list_one,
  get_help_center_list,
  get_help_center_list_one,
  post_help_center_list, put_help_center_list, upDown_help_center_list
} from "../../../service/MedicalConference";
import {update_File} from "../../../utils/update";
import E from 'wangeditor'
import {getLiveOne} from "../../../service/liveMeet_api2";
export default {
  name: "helpCenter",
  data(){
    return{
      listId:'',
      spinning:false,
      labelCol: { span: 4},
      wrapperCol: { span: 14 },
      selectId:[1],
      // "mac","ios"，"win","android"
      templateList:[
        {
          id:1,
          typeName:'全部类型',
          type:'',
        },{
          id:2,
          typeName:'电脑客户端-Windows',
          type:'win',
        },{
          id:3,
          typeName:'电脑客户端-Mac',
          type:'mac',
        },{
          id:4,
          typeName:'手机APP-Android',
          type:'android',
        },{
          id:5,
          typeName:'手机APP-IOS',
          type:'ios',
        },
      ],
      template_list:[
        {
          id:1,
          typeName:'电脑客服端-Windows',
          type:'win',
        },
          {
          id:2,
          typeName:'电脑客户端-Mac',
          type:'mac',
        },
        {
          id:3,
          typeName:'手机APP-Android',
          type:'android',
        },
        {
          id:4,
          typeName:'手机APP-IOS',
          type:'ios',
        },
      ],
      keyword_val:'',
      columns:[
        {
          title: '标题',
          dataIndex: 'title',
          key: 'title',
          width:'60%',
        },
        {
          title: '更新时间',
          dataIndex: 'updateTime',
          key: 'updateTime',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      tableList:[],
      total:0,
      addVisible:false,
      restVisible:false,
      page_no:1,
      typeCode:'',
      content_add_form:{
        title:'',
        content:'',
        type: [
          // "mac",
          // "ios"
        ],
        appendix: [
          // {
          //   name: "",
          //   value: ""
          // }
        ],
      },
      content_add_rules: {
        title: [{required: true, message: '请输入', trigger: 'blur'}],
        content: [{required: true, message: '请输入', trigger: 'blur'}],
        type: [{required: true, message: '请选择', trigger: 'blur'}],
        // appendix: [{required: true, message: '请上传', trigger: 'blur'}],
      },
      content_rest_form:{
        title:'',
        content:'',
        type: [
          // "mac",
          // "ios"
        ],
        appendix: [
          // {
          //   name: "",
          //   value: ""
          // }
        ],
      },
      editor:null,
      editors:null,
      test:null,
      editor_menus:[
        'head', // 标题
        'bold', // 粗体
        'fontSize', // 字号
        'fontName', // 字体
        'italic', // 斜体
        'underline', // 下划线
        'strikeThrough', // 删除线
        'foreColor', // 文字颜色
        'backColor', // 背景颜色
        'link', // 插入链接
        'list', // 列表
        'justify', // 对齐方式
        'quote', // 引用
        'emoticon', // 表情
        'image', // 插入图片
        'table', // 表格
        'video', // 插入视频
        'code', // 插入代码
        'undo', // 撤销
        'redo', // 重复
        'fullscreen' // 全屏
      ],
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"帮助中心内容管理")
    // this.getList()
  },
  async mounted() {
    await this.getList()

  },
  methods:{
    //获取接口列表
    async getList(page_no,title,aType) {
      this.spinning = true
      let data = {
        title:title,
        page_no:page_no,
        aType:aType,
        // isAsc:isAsc,
        // orderByField:orderByField
      }
      const response = await get_help_center_list(data)
      if(response.code === 0){
        this.tableList = response.data.rows
        this.total = response.data.count
      }else {
        this.$message.warning("失败"+response.message)
      }
      this.spinning = false
    },
    selectStatus(id,type){
      //清空关键字
      this.keyword_val = ''
      this.selectId = [id]
      //类型
      this.typeCode = type
      this.getList(this.page_no, this.keyword_val,this.typeCode)
      // 恢复页码1
      this.page_no = 1
    },
    selectBtn(){
      this.getList(this.page_no, this.keyword_val,this.typeCode)
    },
    restBtn(){
      this.keyword_val = ''
      this.getList(this.page_no, this.keyword_val, this.typeCode)
    },
    addBtn(){
      this.addVisible = true
      //创建以及销毁富文本编辑器
      this.$nextTick(()=>{
        if(this.editor === null){
          this.create_editor_config()
        }else {
          this.editor.destroy()
          this.create_editor_config()
        }
      })
    },

    async content_pdf() {
      this.spinning = true
      let inputDOM = this.$refs.content_pdfUrl.files[0];
      const fileVal = await update_File(inputDOM)
      if (fileVal.code === 0) {
        let appendix = {}
        appendix.name = inputDOM.name
        appendix.value = fileVal.data.url
        appendix.percentAge = 100
        appendix.size = inputDOM.size
        this.content_add_form.appendix.push(appendix)

        this.$message.success("上传成功")
      } else {
        this.$message.warning("上传失败")
      }
      this.spinning = false
    },
    async content_pdf_rest() {
      this.spinning = true
      let inputDOM = this.$refs.content_pdfUrl_rest.files[0];
      const fileVal = await update_File(inputDOM)
      if (fileVal.code === 0) {
        let appendix = {}
        appendix.name = inputDOM.name
        appendix.value = fileVal.data.url
        appendix.percentAge = 100
        appendix.size = inputDOM.size
        this.content_rest_form.appendix.push(appendix)
        this.$message.success("上传成功")
      } else {
        this.$message.warning("上传失败")
      }
      this.spinning = false
    },
    //富文本编辑器
    create_editor_config(){
        //等待DOM加载完毕
        const editor =  new E(this.$refs.editor)
        this.editor = editor
        //去除图片视频上传
        // editor.config.excludeMenus = [
        //   'image',
        //   'video'
        // ]
      //配置菜单栏
      editor.config.menus = this.editor_menus
        // 设置编辑区域高度为 500px
        editor.config.height =200
        //创建编辑器
        editor.create()
    },
    async create_editor_configs(){
      //等待DOM加载完毕
      const editor =  new E(this.$refs.editors)
      this.editors = editor
      //去除图片视频上传
      editor.config.excludeMenus = [
        'image',
        'video'
      ]
      //设置编辑区域高度为 500px
      editor.config.height =100
      //创建编辑器
      await editor.create()
    },
    pageChange(page_no){
      this.page_no = page_no
      this.getList(this.page_no, this.keyword_val,this.typeCode)
    },

    //获取接口单个详情
    async getOneLiveList(id) {
      const response = await get_help_center_list_one(id)
      if(response.code === 0){
        this.editors.txt.html(response.data.content);
      }else {
        this.$message.warning("失败"+response.message)
      }
    },
    lookBtn(row) {
      this.listId = row.id
      this.restVisible = true
      //赋值
      let form = this.content_rest_form
      form.title = row.title
      form.type = row.type
      form.appendix = row.appendix
      form.content = row.content
      this.getOneLiveList(row.id)
      //创建以及销毁富文本编辑器
      this.$nextTick(()=>{
        if(this.editors === null){
          this.create_editor_configs()
        }else {
          this.editors.destroy()
          this.create_editor_configs()
        }
      })
    },
    async amendBtn(id) {
      const response = await del_help_center_list_one(id)
      if (response.code === 0) {
        this.$message.success("删除成功！")
        //  刷新列表
        await this.getList(this.page_no, this.keyword_val, this.typeCode)
      } else {
        this.$message.warning("失败" + response.message)
      }
    },
    addSubmit(){
      this.content_add_form.content = this.editor.txt.html()
      this.$refs.content_add_ruleForm.validate(async valid => {
        if (valid) {
          const response = await post_help_center_list(this.content_add_form)
          if(response.code === 0){
            this.$message.success("新增成功！")
          //  关闭对话框
            this.addVisible = false
          //  清空
            this.$refs.content_add_ruleForm.resetFields();
            this.content_add_form.content = ''
          //  刷新列表
            await this.getList(this.page_no, this.keyword_val, this.typeCode)
          }else {
            this.$message.warning("失败"+response.message)
          }
        } else {
          this.$message.warning("有空~~")
          console.log(this.content_add_form.content)
          return false;
        }
      });
    },
    async restSubmit() {
      this.content_rest_form.content = this.editors.txt.html()
      let id = this.listId
      const response = await put_help_center_list(id,this.content_rest_form)
      if (response.code === 0) {
        this.$message.success("修改成功！")
        //  关闭对话框
        this.restVisible = false
        this.content_rest_form.title = ''
        this.content_rest_form.type = []
        this.content_rest_form.appendix = []
        this.content_rest_form.content = ''
        //  刷新列表
        await this.getList(this.page_no, this.keyword_val, this.typeCode)
      } else {
        this.$message.warning("失败" + response.message)
      }
    },
    addClose(){
      this.addVisible = false
      //  清空
      this.$refs.content_add_ruleForm.resetFields();
      this.content_add_form.content = ''
    },
    restClose(){
      this.restVisible = false
    },
    //获取接口单个详情
    async upDown(id,action) {
      let data = {
        aType:this.typeCode
      }
      const response = await upDown_help_center_list(id,action,data)
      if(response.code === 0){
        this.$message.success("操作成功！")
        //刷新列表
        await this.getList(this.page_no, this.keyword_val, this.typeCode)
      }else {
        this.$message.warning("失败"+response.message)
      }
    },
    upBtn(row){
      let actions = 'up'
      this.upDown(row.id,actions)
    },
    downBtn(row){
      let actions = 'down'
      this.upDown(row.id,actions)
    },
    openFile(file){
      window.open(file)
    },
    openFileRest(file){
      window.open(file)
    },
  }
}
</script>

<style scoped lang="scss">
.ZKAuth{
  width: 100%;
  display: flex;
  .ZKAuth-left{
    width: 15%;
  }
  .ZKAuth-right{
    width: 85%;
    border-left: 15px solid #f5f5f5;
    min-height: 800px;
    .ZKAuth-right-top{
      padding-top: 15px;
      width: 100%;
      height: 120px;
      border-bottom: 10px solid #f5f5f5;
      padding-left: 30px;
    }
    .ZKAuth-right-table{
      width: 100%;
      .ZKAuth-right-table-top-btn{
        width: 100%;
        height: 50px ;
        margin-left: 20px;
        margin-top: 15px;
      }
      .ZKAuth-right-table-top-table{
        width: 100%;
        margin-left: 15px;
      }
    }
    .pageClass{
      width: 100%;
      text-align: right;
      float: right;
      margin-top: 15px;
    }
  }
}
.uploadpdf{
  width: 450px;
  height: 150px;
  background-image: url("../../../assets/pdfs.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
}
</style>